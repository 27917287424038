import React, { CSSProperties, ReactChild, useState } from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';
import Header from '../Header';
import Footer from '../Footer';
import Wrapper from '../Wrapper';

interface BaseLayoutProps {
  // children
  children: ReactChild;
  // style
  style?: CSSProperties;
  // className
  className?: string;
}

const BaseLayout = (props: BaseLayoutProps) => {
  const { children, style, className } = props;
  return (
    <div className={classnames(styles.layoutWrapper, className)} style={style}>
      <Header />
      <div className={styles.fixedHeader}>{children}</div>
      <Wrapper className={styles.footer}>
        <Footer />
      </Wrapper>
    </div>
  );
};

export default BaseLayout;

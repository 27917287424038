import { ButtonProps, InputProps } from '@mitra/sg-design';

import dynamic from 'next/dynamic';
import Link from 'next/link';
import Router from 'next/router'
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import Wrapper from '../Wrapper';
import styles from './index.module.scss';
import i18n from '@/i18n';
import { getStaticPath } from '@/utils/staticPath'
const { useTranslation } = i18n;

type navType = 'news' | 'discount_policy' | 'Q&A' | 'contact_us';
export interface HeaderProps {
  currentPage?: navType;
}

const Button = dynamic<ButtonProps>(
  () => import('@mitra/sg-design').then((mod) => mod.Button),
  { ssr: false }
);
const Header = (props: HeaderProps) => {
  const [inputValue, setInputValue] = useState('');
  const [phoneButtonHovered, setPhoneButtonHovered] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const { pathname, push: routerPush } = useRouter();
  useEffect(() => {
    // 跳转链接时关闭modal
    if (modalVisible) setModalVisible(false);
  }, [pathname]);
  const { t } = useTranslation();

  return (
    <div className={styles.headerBox}>
      <Wrapper
        className={styles.headerWrapper}
        style={{
          background: '#fff',
          height: 64,
          position: 'fixed',
          width: '100%',
        }}
      >
        <div className={styles.fixedBox}>
          <div className={styles.headerContainer}>
            <div className={styles.headerLeft}>
              <Link href="/benefits">
                <img
                  src={getStaticPath("/images/logo.svg")}
                  alt="Shopee Negosyo"
                  height={30.53}
                />
              </Link>
            </div>

            <div className={styles.headerRight}>
              <nav className={styles.headerMiddle}>
                <ul>
                  <li
                    className={
                      pathname === '/benefits' || pathname === '/' ? styles.active : undefined
                    }
                  >
                    <Link href="/benefits">{t('pw_ph_benefits')}</Link>
                  </li>
                  <li
                    className={
                      pathname === '/services' ? styles.active : undefined
                    }
                  >
                    <Link href="/services">{t('pw_ph_services')}</Link>
                  </li>
                  <li
                    className={pathname === '/faqs' ? styles.active : undefined}
                  >
                    <Link href="/faqs">{t('pw_ph_faqs')}</Link>
                  </li>
                </ul>
              </nav>
              <Button
                type={'primary'}
                style={{ height: 36 }}
                onClick={() => {
                  Router.push('/benefits').then(() => {
                    window.scrollTo(0, 1600)
                  })
                }}
              >
                {t('pw_ph_register_now')}
              </Button>
            </div>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default Header;

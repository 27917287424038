import React, { useEffect } from 'react';
import i18n from '@/i18n';
import BaseLayout from '@/components/BaseLayout';
import '@mitra/sg-design/dist/index.css';
import '../styles/index.scss';
import Head from 'next/head';
import { getProcessEnv } from 'utils/processEnv';

const { appWithTranslation } = i18n;
/**
 * 自定义 layout
 * @param Component
 * @param pageProps
 * @constructor
 */
function App({ Component, pageProps }) {
  useEffect(() => {
    import('@/utils/mdapExtra');
    //平台、设备和操作系统 
    // let system = {
    //   win: false, mac: false, xll: false, ipad: false
    // };
    // //检测平台 
    // let p = navigator.platform;
    // system.win = p.indexOf("Win") == 0;
    // system.mac = p.indexOf("Mac") == 0;
    // system.xll = (p == "Xll") || (p.indexOf("Linux") == 0);
    // system.ipad = (navigator.userAgent.match(/iPad/i) != null) ? true : false;
    // //跳转语句，如果是手机访问就自动跳转到wap.baidu.com页面 
    // if (system.win || system.mac || system.xll || system.ipad) {

    // } else {
    //   window.location.href = "https://m.negosyo.test.shopee.ph";
    // }
    // PC端 移动端 跳转 
    var is_mobi = navigator.userAgent.toLowerCase().match(/(ipad|iphone|android|coolpad|mmp|smartphone|midp|wap|xoom|symbian|j2me|blackberry|wince)/i) != null;
    console.log('getProcessEnv:', getProcessEnv())
    let env = getProcessEnv() || 'test'
    if (is_mobi) {
      window.location.href = `https://m.negosyo.${env}.shopee.ph${window.location.pathname}`;
    }
    else {
    }
  }, [])
  return (
    <>
      <Head>
        <title>Shopee Negosyo</title>
        <link rel="icon" href="https://static.negosyo.shopee.ph/favicon.ico" />
      </Head>
      <BaseLayout>
        <Component {...pageProps} />
      </BaseLayout>
    </>
  );
}
export default appWithTranslation(App);

import Link from 'next/link';
import React from 'react';

import styles from './index.module.scss';
import i18n from '@/i18n';
import { getStaticPath } from '@/utils/staticPath'
const { useTranslation } = i18n;

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.footerWrapper}>
      <div className={styles.footerTop}>
        <div className={styles.footerLeft}>
          <img src={getStaticPath("/images/logo.svg")} alt="" height={32} />
          <p>{t('pw_ph_customer_service')}</p>
          <div className={styles.borderBox}>
            <img src={getStaticPath("/images/Vector.png")} alt="" />
            <span>(02) 8880 5200</span>
          </div>
        </div>
        <div className={styles.footerRight}>
          {/* <ul>
            <li>
              <Link href="/">{t('pw_homepage')}</Link>
            </li>
          </ul>
          */}
          <div className={styles.IconGroup}>
            <div className={styles.IconGroupLeft}>
              <a target="_blank" rel="noopener noreferrer" href="https://info.dailyshopee.vn/"><img src={getStaticPath("/images/AppleIcon.svg")} alt="" /></a>
              <a target="_blank" rel="noopener noreferrer" href="https://info.dailyshopee.vn/"><img src={getStaticPath("/images/GoogleIcon.svg")} alt="" /></a>
            </div>
            <div className={styles.IconGroupRight}>
              <img src={getStaticPath("/images/QRCode.png")} alt="" />
              <p>Scan QR to download</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footerBottom}>
        <span>{t('pw_ph_company_claim')}</span>
      </div>
    </div>
  );
};

export default Footer;
